import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //styleUrls: ['./theme/scss/theme.scss']
})
export class AppComponent {
  title = 'su host';
  dynyear: number = new Date().getFullYear();
}
