<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<main class="main" id="top">
  <nav class="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark navbar-theme">
    <div class="container"><a class="navbar-brand" href="../index.html"><span class="text-white"><i class="fa fa-power-off"></i> su <strong>host</strong></span></a>
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse" id="navbarStandard">
        
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link" href="#features">Features</a></li>
          <li class="nav-item dropdown dropdown-on-hover"><a class="nav-link dropdown-toggle" id="navbarDropdownSupport" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
            <div class="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownSupport">
              <div class="card shadow-none navbar-card-login">
                <div class="card-body fs--1 p-4 font-weight-normal">
                  <div class="row text-left justify-content-between align-items-center mb-2">
                    A fully featured client portal offering documentation and the ability to create support tickets is coming soon!  Until then, contact your account manager.
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        
        <ul class="navbar-nav ml-auto">
          <!--<li class="nav-item"><a class="nav-link" href="../index.html"><span class="fas fa-chart-pie d-none d-lg-inline-block" data-toggle="tooltip" data-placement="bottom" title="Dashboard"></span><span class="d-lg-none">Dashboard</span></a></li>-->
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" id="navbarDropdownLogin" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Login</a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-card" aria-labelledby="navbarDropdownLogin">
              <div class="card shadow-none navbar-card-login">
                <div class="card-body fs--1 p-4 font-weight-normal">
                  <div class="row text-left justify-content-between align-items-center mb-2">
                    <div class="col-auto">
                      <h5 class="mb-0">Log in</h5>
                    </div>
                    <!--<div class="col-auto">
                      <p class="fs--1 text-600 mb-0">or <a href="../authentication/basic/register.html">Create an account</a></p>
                    </div>-->
                  </div>
                  A fully featured client portal is coming soon!
                  <!--<form>
                    <div class="form-group">
                      <input class="form-control" type="email" placeholder="Email address" />
                    </div>
                    <div class="form-group">
                      <input class="form-control" type="password" placeholder="Password" />
                    </div>
                    <div class="row justify-content-between align-items-center">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="modal-checkbox" />
                          <label class="custom-control-label" for="modal-checkbox">Remember me</label>
                        </div>
                      </div>
                      <div class="col-auto"><a class="fs--1" href="../authentication/basic/forgot-password.html">Forgot Password?</a></div>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary btn-block mt-3" type="submit" name="submit">Log in</button>
                    </div>
                  </form>
                  <div class="w-100 position-relative mt-4">
                    <hr class="text-300" />
                    <div class="position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap">or log in with</div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="row no-gutters">
                      <div class="col-sm-6 pr-sm-1"><a class="btn btn-outline-google-plus btn-sm btn-block mt-2" href="#"><span class="fab fa-google-plus-g mr-2" data-fa-transform="grow-8"></span> google</a></div>
                      <div class="col-sm-6 pl-sm-1"><a class="btn btn-outline-facebook btn-sm btn-block mt-2" href="#"><span class="fab fa-facebook-square mr-2" data-fa-transform="grow-8"></span> facebook</a></div>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </li>
          <!--<li class="nav-item"><a class="nav-link" href="#!" data-toggle="modal" data-target="#exampleModal">Register</a></li>-->
        </ul>
      </div>
    </div>
  </nav>


  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="py-0 overflow-hidden" id="banner">

    <div class="bg-holder overlay opacity-85" style="background-image:url(app/theme/pages/assets/img/su-host/bg.jpg);background-position: center bottom;">
    </div>
    <!--/.bg-holder-->

    <div class="container">
      <div class="row justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <div class="col-md-11 col-lg-8 col-xl-4 pb-7 pb-xl-9 text-center text-xl-left">
          <h1 class="text-white font-weight-light">Bring <span class="typed-text font-weight-bold" data-typed-text='["speed","reliability","security"]'></span><br />to your application</h1>
          <p class="lead text-white">You and your application deserve the best!  Let us worry about delivery and you can focus on building your awesome applications!</p><a class="btn btn-outline-light border-2x rounded-pill btn-lg mt-4 fs-0 py-2" href="#features">View Standard Features<span class="fas fa-play" data-fa-transform="shrink-6 down-1 right-5"></span></a>-
        </div>
        <div class="col-xl-7 offset-xl-1 align-self-end"><!--<a class="img-landing-banner" href="app/theme/pages/index.html"><img class="img-fluid d-dark-none" src="app/theme/pages/assets/img/generic/dashboard-alt.png" alt="" /><img class="img-fluid d-light-none" src="app/theme/pages/assets/img/generic/dashboard-alt-dark.png" alt="" /></a>--></div>
      </div>
    </div>
    <!-- end of .container-->

  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->


  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <a name="features"></a>
    <section class="bg-light text-center">

    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="fs-2 fs-sm-4 fs-md-5">Amazing Features</h1>
          <p class="lead">Things you will get right out of the box with <i class="fa fa-power-off"></i>&nbsp;<strong>su</strong> host</p>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col-lg-4">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-cloud fs-4 text-primary"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">Cloud Based</h5>
              <p>Our systems are cloud based, allowing us to quickly scale for your application. Additionally it provides high availability and full customization for your needs.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-6 mt-lg-0">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-leaf fs-5 text-success"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">Green Computing</h5>
              <p>Because our systems are cloud based, we can adjust our systems for your application. This reduces waste, helps conserve energy costs and ensures you pay for only what you need.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-6 mt-lg-0">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-cogs fs-6 text-dark"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">High Performance</h5>
              <p>Our servers feature high performance processors, ultra responsive SSD storage, and blazing fast 10 Gbps connections.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-6">
        <div class="col-lg-4">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-clock fs-4 text-muted"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">Quick Deployment</h5>
              <p>Our cloud based systems allow us to quickly deploy and resize our systems for your application.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-6 mt-lg-0">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-server fs-5 text-info"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">OS Options</h5>
              <p>You have a number of Operating System options for your application. Unless your application is custom, we will choose the most appropriate OS.  In many cases, the underlying OS can be abstracted and is not a decision you need to make.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-6 mt-lg-0">
          <div class="card card-span h-100">
            <div class="card-span-img"><span class="fa fa-lock fs-6 text-danger"></span></div>
            <div class="card-body pt-6 pb-4">
              <h5 class="mb-2">Security</h5>
              <p>All systems are secured by industry standards and follow best practices.  We will work with you on any specific needs.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end of .container-->

  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->


  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section>

    <div class="bg-holder overlay" style="background-image:url(app/theme/pages/assets/img/generic/bg-2.jpg);background-position: center top;">
    </div>
    <!--/.bg-holder-->

    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8">
          <p class="fs-3 fs-sm-4 text-white">We are currently not accepting new customers.  In order to provide our existing customers with an even better experience, we are aggressively upgrading our sytems.  We look forward to welcoming your application in the future!</p>
        </div>
      </div>
    </div>
    <!-- end of .container-->

  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="py-0 bg-dark">

    <div>
      <hr class="my-0 border-600 opacity-25" />
      <div class="container py-3">
        <div class="row justify-content-between fs--1">
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600 opacity-85">Where you are in control! <span class="d-none d-sm-inline-block">| <a class="text-white opacity-85" href="https://su-host.com"><i class="fa fa-power-off"></i>&nbsp;<strong>su</strong> host</a> <br class="d-sm-none" /> &copy; {{ dynyear }} </span></p>
          </div>
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600 opacity-85"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- end of .container-->

  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->


</main>
<!-- ===============================================-->
<!--    End of Main Content-->
<!-- ===============================================-->


<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<router-outlet></router-outlet>